var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'mob-video',
    _vm.myApply && (_vm.data.type == 1 || (_vm.data.liveStatus == 2 && _vm.data.type == 0))
      ? 'past'
      : '' ]},[_c('div',{staticClass:"info",on:{"click":function($event){return _vm.toWatch()}}},[_c('div',{staticClass:"kv"},[_c('img',{attrs:{"src":_vm.data.kvUrl,"alt":""}}),_c('div',{staticClass:"model"}),_c('img',{staticClass:"play-icon",attrs:{"src":require("../assets/play.png"),"alt":""}})]),_c('div',{staticClass:"course-info"},[(_vm.data.type == 0 && _vm.data.liveStatus != 2)?_c('div',{staticClass:"status"},[_c('div',{staticClass:"live-time-box"},[(_vm.data.liveStatus == 0)?_c('div',{staticClass:"live-time"},[_vm._v(" "+_vm._s(_vm.data.liveTime)+" ")]):_vm._e()]),_c('div',[(_vm.data.type == 0)?_c('div',{class:[
              'live-status',
              _vm.data.liveStatus == 0
                ? 'blue'
                : _vm.data.liveStatus == 1
                ? 'green'
                : '' ]},[_vm._v(" "+_vm._s(_vm.data.liveStatus == 0 ? "即将开始" : _vm.data.liveStatus == 1 ? "正在直播" : "")+" ")]):_vm._e()])]):_vm._e(),_c('h3',{staticClass:"course-title hidden2"},[_vm._v(_vm._s(_vm.data.subject))]),_c('div',{staticClass:"lecturer"},_vm._l((_vm.data.liveLecturerListMap),function(item,i){return _c('p',{key:i},[_vm._m(0,true),_c('span',{staticClass:"lecturer-name"},[_vm._v(_vm._s(item.lecturerName))]),_c('span',{staticClass:"lecturer-organization"},[_vm._v(_vm._s(item.companyName))])])}),0)])]),_c('div',{staticClass:"information"},[_c('div',{staticClass:"course-information"},[_c('span',{staticClass:"channel-name"},[_vm._v(_vm._s(_vm.data.channelName + "频道"))]),(
          _vm.data.liveStatus == 0 && _vm.data.type == 0 && _vm.data.applySumStatus == 1
        )?_c('span',{staticClass:"num-data"},[_c('span',{staticClass:"bar"}),_vm._v(_vm._s(("预约人数 " + (parseInt(_vm.data.applySum) + _vm.data.virtualApplySum) + "人")))]):_vm._e(),((_vm.data.liveStatus != 0 || _vm.data.type == 1) && _vm.data.pvStatus == 1)?_c('span',{staticClass:"num-data"},[_c('span',{staticClass:"bar"}),_vm._v(_vm._s(("观看人数 " + (_vm.data.viryualPv + _vm.data.watchNumber) + "人")))]):_vm._e()]),(!_vm.myCollect && !_vm.myApply)?_c('div',{staticClass:"course-btn"},[_c('button',{class:['collect-btn', _vm.data.ymnCollect != null ? 'active' : ''],on:{"click":function($event){return _vm.collectFn()}}},[_vm._v(" "+_vm._s(_vm.data.ymnCollect != null ? "已收藏" : "收藏")+" ")]),(_vm.data.liveStatus == 0 && _vm.data.type == 0)?_c('button',{class:['apply-btn', _vm.data.ymnSubscribe != null ? 'active' : ''],on:{"click":function($event){return _vm.appointmentFn()}}},[_vm._v(" "+_vm._s(_vm.data.ymnSubscribe != null ? "已预约" : "预约")+" ")]):_vm._e()]):_c('div',{staticClass:"course-btn"},[(_vm.myCollect)?_c('button',{staticClass:"collect-btn active",on:{"click":function($event){return _vm.collectFn()}}},[_vm._v(" "+_vm._s(_vm.data.ymnCollect != null ? "已收藏" : "收藏")+" ")]):_vm._e(),(_vm.myApply)?_c('button',{staticClass:"apply-btn active",on:{"click":function($event){return _vm.appointmentFn()}}},[_vm._v(" "+_vm._s(_vm.data.ymnSubscribe != null ? "已预约" : "预约")+" ")]):_vm._e()])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('i',[_c('img',{attrs:{"src":require("../assets/lecturer.png"),"alt":""}})])}]

export { render, staticRenderFns }
import wx from "weixin-js-sdk";

const wechat = {
  share(options, title, info, img, url) {
    wx.config({
      debug: false,
      appId: options.appId,
      timestamp: options.timestamp,
      nonceStr: options.nonceStr,
      signature: options.signature,
      jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"],
    });
    wx.ready(function () {
      wx.updateTimelineShareData({
        // 分享标题
        title: title,
        // 分享链接
        link: options.url,
        // 分享图标
        imgUrl: img,
        success: function success() {},
        cancel: function cancel() {
          // 用户取消分享后执行的回调函数
        },
      });
      wx.updateAppMessageShareData({
        // 分享标题
        title: title,
        // 分享描述
        desc: info,
        // 分享链接
        link: url,
        // 分享图标
        imgUrl: img,
        // 分享类型,music、video或link，不填默认为link
        type: "",
        // 如果type是music或video，则要提供数据链接，默认为空
        optionsUrl: "",
        success: function success() {
          // alert(123);
          // 用户确认分享后执行的回调函数
        },
        cancel: function cancel() {
          // 用户取消分享后执行的回调函数
        },
      });
    });
    wx.error(function () {
      // alert('err---' + JSON.stringify(res));
    });
  },
};

export default wechat;

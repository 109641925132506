<template>
  <div
    :class="[
      'mob-video',
      myApply && (data.type == 1 || (data.liveStatus == 2 && data.type == 0))
        ? 'past'
        : '',
    ]"
  >
    <div class="info" @click="toWatch()">
      <!-- 课程kv -->
      <div class="kv">
        <img :src="data.kvUrl" alt="" />
        <div class="model"></div>
        <img class="play-icon" src="../assets/play.png" alt="" />
      </div>
      <div class="course-info">
        <div class="status" v-if="data.type == 0 && data.liveStatus != 2">
          <!-- 直播时间 -->
          <div class="live-time-box">
            <div v-if="data.liveStatus == 0" class="live-time">
              {{ data.liveTime }}
            </div>
          </div>
          <!-- 课程状态 -->
          <div>
            <div
              v-if="data.type == 0"
              :class="[
                'live-status',
                data.liveStatus == 0
                  ? 'blue'
                  : data.liveStatus == 1
                  ? 'green'
                  : '',
              ]"
            >
              {{
                data.liveStatus == 0
                  ? "即将开始"
                  : data.liveStatus == 1
                  ? "正在直播"
                  : ""
              }}
            </div>
          </div>
        </div>
        <!-- 课程标题 -->
        <h3 class="course-title hidden2">{{ data.subject }}</h3>
        <!-- 讲师 -->
        <div class="lecturer">
          <p v-for="(item, i) in data.liveLecturerListMap" :key="i">
            <i>
              <img src="../assets/lecturer.png" alt="" />
            </i>
            <span class="lecturer-name">{{ item.lecturerName }}</span>
            <span class="lecturer-organization">{{ item.companyName }}</span>
          </p>
        </div>
      </div>
    </div>
    <div class="information">
      <div class="course-information">
        <span class="channel-name">{{ data.channelName + "频道" }}</span>
        <!-- <span class="bar"></span> -->
        <span
          class="num-data"
          v-if="
            data.liveStatus == 0 && data.type == 0 && data.applySumStatus == 1
          "
          ><span class="bar"></span
          >{{
            `预约人数 ${parseInt(data.applySum) + data.virtualApplySum}人`
          }}</span
        >
        <span
          class="num-data"
          v-if="(data.liveStatus != 0 || data.type == 1) && data.pvStatus == 1"
          ><span class="bar"></span
          >{{ `观看人数 ${data.viryualPv + data.watchNumber}人` }}</span
        >
        <!-- <span>{{
          data.liveStatus == 0 && data.type == 0
            ? `预约人数 ${data.virtualApplySum}人`
            : `观看人数 ${data.viryualPv}人`
        }}</span> -->
      </div>
      <div v-if="!myCollect && !myApply" class="course-btn">
        <button
          :class="['collect-btn', data.ymnCollect != null ? 'active' : '']"
          @click="collectFn()"
        >
          {{ data.ymnCollect != null ? "已收藏" : "收藏" }}
        </button>
        <button
          v-if="data.liveStatus == 0 && data.type == 0"
          :class="['apply-btn', data.ymnSubscribe != null ? 'active' : '']"
          @click="appointmentFn()"
        >
          {{ data.ymnSubscribe != null ? "已预约" : "预约" }}
        </button>
      </div>
      <div v-else class="course-btn">
        <button
          v-if="myCollect"
          class="collect-btn active"
          @click="collectFn()"
        >
          {{ data.ymnCollect != null ? "已收藏" : "收藏" }}
        </button>
        <button
          v-if="myApply"
          class="apply-btn active"
          @click="appointmentFn()"
        >
          {{ data.ymnSubscribe != null ? "已预约" : "预约" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { collect, unCollect, appointment, unAppointment } from "../api/user";
export default {
  props: {
    data: {
      type: Object,
      default: function () {
        return {};
      },
    },
    myCollect: {
      type: Boolean,
      default: false,
    },
    myApply: {
      type: Boolean,
      default: false,
    },
    // userId: Number,
  },
  data() {
    return {
      // userId: JSON.parse(sessionStorage.getItem("userInfo")).id,
      // userId: this.$store.state.userId,
      // collectStatus: this.data.ymnCollect,
      // appointmentStatus: this.data.ymnSubscribe,
    };
  },
  methods: {
    // 收藏
    collectFn() {
      if (this.data.ymnCollect != null) {
        // 已收藏
        unCollect({
          id: this.data.ymnCollect.id,
          courseId: this.data.id,
        }).then((res) => {
          if (res.code == 0) {
            this.$ylmessage.success("已取消收藏");
            this.$emit("refDataList");
            // this.collectStatus = null;
          }
        });
      } else {
        // 收藏
        collect({
          courseId: this.data.id,
          // userId: this.userId,
        }).then((res) => {
          if (res.code == 0) {
            this.$ylmessage.success("收藏成功");
            this.$emit("refDataList");
            // this.collectStatus = 1;
          } else {
            this.$router.push({ path: "/login" });
          }
        });
      }
    },
    // 预约
    appointmentFn() {
      if (this.data.ymnSubscribe != null) {
        // 已预约
        unAppointment({
          id: this.data.ymnSubscribe.id,
          courseId: this.data.id,
        }).then((res) => {
          if (res.code == 0) {
            this.$ylmessage.success("已取消预约");
            this.$emit("refDataList");
            // this.appointmentStatus = null;
          }
        });
      } else {
        // 预约
        appointment({
          courseId: this.data.id,
          // userId: this.userId,
          type: "",
        }).then((res) => {
          if (res.code == 0) {
            this.$ylmessage.success("预约成功");
            this.$emit("refDataList");
            // this.appointmentStatus = 1;
          } else {
            this.$router.push({ path: "/login" });
          }
        });
      }
    },
    toWatch() {
      let scourceTag = this.$route.query.st ? '?st=' + this.$route.query.st : ''
      this.$router.push({ path: "/watch/" + this.data.id + scourceTag });
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  width: 100%;
  display: block;
}
h3,
p {
  margin: 0;
}
.past,
.past h3,
.past span,
.past button {
  color: #d2d1d1 !important;
}
.mob-video {
  margin-bottom: 0.1rem;
  width: 100%;
  overflow: hidden;
  background: #fff;
  .info {
    display: flex;
    padding: 0.1rem;
    border-bottom: 1px solid #ececec;
    .kv {
      flex-shrink: 0;
      position: relative;
      margin-right: 0.1rem;
      width: 1.1rem;
      height: 0.6rem;
      border-radius: 0.02rem;
      overflow: hidden;
      .model {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #000000, $alpha: 0.1);
        // z-index: 1;
      }
      .play-icon {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        width: 0.18rem;
        height: 0.18rem;
      }
    }
    .course-info {
      flex-grow: 1;
      .status {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 0.05rem;
        .live-status {
          padding: 0 0.03rem;
          font-size: 0.11rem;
          -webkit-transform-origin-x: 0; //缩小后文字居左
          -webkit-transform: scale(0.9);
        }
        .green {
          border: 1px solid #49b67b;
          color: #49b67b;
        }
        .blue {
          border: 1px solid #1872bb;
          color: #1872bb;
        }
        .live-time-box {
          height: 0.1rem;
          .live-time {
            font-size: 0.1rem;
            color: #333;
            // -webkit-transform-origin-x: 0; //缩小后文字居左
            // -webkit-transform: scale(0.98);
          }
        }
      }
      .course-title {
        font-size: 0.15rem;
        line-height: 0.17rem;
        // text-align: justify;
        // padding: 0.05rem 0;
        // padding-bottom: 0.05rem;
        color: #333;
      }
      .lecturer {
        font-size: 0.11rem;
        i {
          flex-shrink: 0;
          display: inline-block;
          width: 0.09rem;
          height: 0.09rem;
        }
        .lecturer-name {
          flex-shrink: 0;
          padding: 0 0.03rem 0 0.05rem;
          color: #000;
          -webkit-transform-origin-x: 0; //缩小后文字居左
          -webkit-transform: scale(0.9);
        }
        .lecturer-organization {
          color: #999;
          max-width: 1.4rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          -webkit-transform-origin-x: 0; //缩小后文字居左
          -webkit-transform: scale(0.9);
        }
        p {
          display: flex;
          align-items: center;
          margin-top: 0.05rem;
        }
      }
    }
  }
  .information {
    font-size: 0.12rem;
    height: 0.45rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.1rem;
    .channel-name {
      font-weight: bold;
      color: #333;
    }
    .bar {
      border-right: 1px solid #999;
      margin: 0 0.08rem;
    }
    span.num-data {
      color: #999;
    }
    .course-btn {
      button {
        font-size: 0.12rem;
        height: 0.25rem;
        padding: 0 0.15rem;
        border-radius: 0.13rem;
        font-weight: bold;
        margin-left: 0.1rem;
        border: 1px solid #49b67b;
      }
      button.collect-btn {
        color: #49b67b;
        background-color: #fff;
      }
      button.apply-btn {
        background-color: #49b67b;
        color: #fff;
      }
      button.active {
        border: 1px solid #ececec;
        color: #999;
        font-weight: normal;
        background-color: #fff;
      }
    }
  }
}
</style>

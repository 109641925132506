<template>
  <div class="page-view">
    <mob-header
      :interaction="false"
      status="info"
      :username="true"
    ></mob-header>
    <div class="main">
      <span v-if="dataList.length == 0" class="dataNull">暂无课程</span>
      <mob-video
        v-else
        v-for="(item, i) in dataList"
        :key="i"
        :data="item.liveMap"
        :myApply="true"
        @refDataList="getDataList()"
      ></mob-video>
    </div>
    <mob-footer-bar :active="3"></mob-footer-bar>
  </div>
</template>

<script>
import MobHeader from "../../components/mob-header.vue";
import MobFooterBar from "../../components/mob-footer-bar.vue";
import MobVideo from "../../components/mob-video.vue";
import { appointmentList, wechatConfig } from "../../api/user";
import wechat from "../../utils/wxShare";
export default {
  components: {
    MobHeader,
    MobFooterBar,
    MobVideo,
  },
  metaInfo: {
    title: "我的预约",
  },
  data() {
    return {
      dataList: [],
    };
  },
  created() {
    this.getDataList();
    this.getWechatConfig();
  },
  methods: {
    getWechatConfig() {
      wechatConfig({
        url: location.href,
      }).then((res) => {
        if (res.code == 0) {
          this.wxConfigData = res.data;
          wechat.share(
            this.wxConfigData,
            "因美纳在线讲堂",
            "因美纳在线讲堂",
            this.$store.state.url,
            location.href
          );
        }
      });
    },
    getDataList() {
      appointmentList().then((res) => {
        if (res.code == 0) {
          this.dataList = res.list;
        } else if (res.code == 60000) {
          this.$router.replace({ path: "/login" });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-view {
  background: url("../../assets/mobbgsmall.png") 0 0 no-repeat;
  background-size: 100% auto;
  .main {
    padding: 0.1rem 0.1rem 0.9rem;
    .dataNull {
      color: #aaa;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
